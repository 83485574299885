import axios from 'axios';
import {BehaviorSubject} from 'rxjs';
import {NotificationManager} from 'react-notifications';

const FileDownload = require('js-file-download');

const serverIntf = (function () {
    let once = false;
    let isWaiting = false;
    let api;
    let retStruct;

    // enrollments functions 
    let enroll;
    let enable;
    let unenroll;
    let ignoreEss;
    let lookup;
    let navRules;

    // Version handling
    let versionSubject;
    let versionSubjectSubscribe;

    // Pinwheel handling
    let waitingSubject;
    let waitingSubjectSubscribe;

    // ESS handling
    let ess;
    let essSubject;
    let essSubjectSubscribe;
    let setESS;

    // Player handling
    let playersSubject;
    let playersSubjectSubscribe;
    let playerIDToEss;
    let players;

    // Games handling
    let gamesSubject;
    let gamesSubjectSubscribe;
    let games;

    // Recall handling
    let recallSubject;
    let recallSubjectSubscribe;
    let recall;

    // Current game handling
    let bingoCardSubject;
    let bingoCardSubjectSubscribe;
    let bingoCard;
    let setGameEss;

    // Login handling
    let tok;
    let eid;
    let login;
    let loginSubject;
    let loginSubjectSubscribe;
    let get;
    let post;

    // report handling
    let sessionReport;
    let enrollmentReport;
    let deckReport;
    let auditReport;

    const shallowEqual = (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);

        if (keys1.length !== keys2.length)
            return false;

        for (let key of keys1) {
            if (object1[key] !== object2[key])
                return false;
        }

        return true;
    }

    // test if two arrays are equal given the object comparator
    const arraysEqual = (cur, next) => {
        if (!Array.isArray(next)) return true; // Don't accept next if not array
        if (!Array.isArray(cur)) return false;  // But if cur not array then accept
        if (cur.length !== next.length) return false; // can't be equal if lengths not equal
        for (let i = 0; i < cur.length; i++) {
            if (!shallowEqual(cur[i], next[i])) return false;
        }
        return true;
    }

    // Test two recall objects for equality
    const recallEqual = (cur, next) => {
        if (cur.ESS !== next.ESS) return false;
        if (cur.AssetNumber !== next.AssetNumber) return false;
        if (cur.FloorLocation !== next.FloorLocation) return false;
        if (cur.SiteID !== next.SiteID) return false;
        return arraysEqual(cur.Games, next.Games);
    }

    // Initialization login to publish the login state from server
    const initlogin = () => {
        loginSubject = new BehaviorSubject(false);
        loginSubjectSubscribe = (subscriber) => {
            return loginSubject.subscribe(subscriber);
        }

        waitingSubject = new BehaviorSubject(false);
        waitingSubjectSubscribe = (subscriber) => {
            return waitingSubject.subscribe(subscriber);
        }

        login = (user, pass) => {
            waitingSubject.next(true);
            return api.post('/login', {}, {auth: {username: user, password: pass}})
                .then((response) => {
                    tok = response.data.Token;
                    eid = response.data.EmployeeID;
                    console.log("login success", response.data);
                    loginSubject.next(true);
                    waitingSubject.next(false);
                    lookup();
                }).catch(err => {
                    console.log("login failed", user);
                    tok = null;
                    loginSubject.next(false);
                    waitingSubject.next(false);
                    throw (err);
                });
        }

        const request = (url, data, method, isBlob, background) => {
            waitingSubject.next(!background);
            let config = {
                url: url,
                data: data,
                method: method,
            };

            if (isBlob)
                config.responseType = 'blob';

            if (tok)
                config.headers = {'Authorization': tok};

            return api.request(config).catch((error) => {
                if (error && error.response) {
                    if (error.response.status === 401 || error.response.status === 403) {
                        if (tok)
                            signoff();
                    }
                }
                throw (error);
            });
        }

        get = (url, isBlob, background) => {
            return request(url, null, 'get', isBlob, background);
        }

        post = (url, data, background) => {
            if (0 < eid && eid <= 4) {
                return request(url, data, 'post', background);
            }

            throw new Error("You do no have sufficient privileges to perform machine configuration");
        }

        const realTimeTask = () => {
            if (tok) {
                get('/reports/json/players', false, true).then((response) => {
                    if (response && response.data) {
                        if (!arraysEqual(players, response.data))
                            playersSubject.next(response.data);
                    }
                }).catch(warn => {
                    parseWarning(warn);
                });
                
                get('/reports/json/games', false, true).then((response) => {
                    if (response && response.data) {
                        if (!arraysEqual(games, response.data))
                            gamesSubject.next(response.data);
                    }
                }).catch(err => {
                    parseError(err);
                });

                if (ess) {
                    get('/reports/json/game-recall/' + ess, false, true).then((response) => {
                        if (response && response.data) {
                            if (!recallEqual(recall, response.data))
                                recallSubject.next(response.data);
                        }
                    }).catch(err => {
                        parseError(err);
                    });
                }
            }
        }
        setInterval(realTimeTask, 1000);

        const periodicTask = () => {
            if (tok) {
                lookup();
            }
        }
        setInterval(periodicTask, 10000);

        enroll = (_ess) => {
            post('/enrollment/enroll/' + _ess).then((response) => {
                return response;
            }).catch(err => {
                parseError(err);
            });
        }

        enable = (_ess) => {
            post('/enrollment/enable/' + _ess).then((response) => {
                return response;
            }).catch(err => {
                parseError(err);
            });
        }

        unenroll = (_ess) => {
            post('/enrollment/unenroll/' + _ess).then((response) => {
                return response;
            }).catch(err => {
                parseError(err);
            });
        }

        ignoreEss = (_ess) => {
            return post('/enrollment/ignore/' + _ess);
        }

        lookup = () => {
            return get('/lookup', false, true).then((response) => {
                return response;
            });
        }

        navRules = () => {
            window.location.pathname = '/Rules';
        }

        // reporting
        const report = (url, name) => {
            get(url, true).then((response) => {
                FileDownload(response.data, name);
                waitingSubject.next(false);
            });
        }

        sessionReport = () => {
            return report('/reports/csv/sessions', 'session.csv');
        }

        enrollmentReport = () => {
            return report('/reports/csv/games', 'enrollment.csv');
        }

        deckReport = () => {
            return report('/reports/csv/deck', 'deck.csv');
        }

        auditReport = () => {
            return report('/reports/csv/audits', 'audits.csv');
        }
    }

    // Initialization versionSubject to publish the version from server
    const initVersion = () => {
        versionSubject = new BehaviorSubject('unknown');
        versionSubjectSubscribe = (subscriber) => {
            return versionSubject.subscribe(subscriber);
        }

        const getVersion = () => {
            fetch('config.json').then((res) => {
                return res.body;
            }).then((data) => {
                versionSubject.next("239c35b361d24a5a1bce0b9679fa4a8c5e1250fd");
            }).catch(err => {
                parseError(err);
            });
            /*
            api.get('/version').then((response) => {
                versionSubject.next(response.data);
            }).catch(err => {
                console.log(err.data);
                setTimeout(getVersion, 2000);
            });
             */
        }

        getVersion();
    }

    // Initialization code to startup server interface. This should be called at most once
    // which we ensure by returning a function 
    const init = () => {
        api = axios.create({
            baseURL: ('https://' + window.location.hostname) + "/bingo-server",
            timeout: 30000,
        });

        // Pinwheel handling
        waitingSubject = new BehaviorSubject(false);
        waitingSubjectSubscribe = (subscriber) => {
            return waitingSubject.subscribe(subscriber);
        }
        waitingSubject.subscribe((_isWaiting) => {
            isWaiting = _isWaiting;
        })

        // Player handling
        playersSubject = new BehaviorSubject([]);
        playersSubjectSubscribe = (subscriber) => {
            return playersSubject.subscribe(subscriber);
        }
        playersSubject.subscribe((_players) => {
            players = _players;
        })

        // Games handling
        gamesSubject = new BehaviorSubject([]);
        gamesSubjectSubscribe = (subscriber) => {
            return gamesSubject.subscribe(subscriber);
        }
        gamesSubject.subscribe((_games) => {
            games = _games;
        })

        // Recall handling
        recallSubject = new BehaviorSubject({})
        recallSubjectSubscribe = (subscriber) => {
            return recallSubject.subscribe(subscriber);
        }
        recallSubject.subscribe((_recall) => {
            recall = _recall;
        })

        // Current game handling
        bingoCardSubject = new BehaviorSubject({});
        bingoCardSubjectSubscribe = (subscriber) => {
            return bingoCardSubject.subscribe(subscriber);
        };
        bingoCardSubject.subscribe((_card) => {
            bingoCard = _card;
        });
        setGameEss = (_ess) => {
            if(_ess !== ess) {
                get('/reports/json/game-recall/' + _ess, false, true).then((response) => {
                    if (response && response.data) {
                        let game = response.data.Games.sort((a, b) => (a.WagerTime > b.WagerTime ? -1 : 1))[0];
                        bingoCardSubject.next(game);
                    }
                }).catch(err => {
                    parseError(err);
                });
            }
        };

        // ess handling
        essSubject = new BehaviorSubject('')
        essSubjectSubscribe = (subscriber) => {
            return essSubject.subscribe(subscriber);
        }
        essSubject.subscribe((_ess) => {
            ess = _ess;
        })
        setESS = (_ess) => {
            if (_ess !== ess) {
                if (_ess) {
                    recallSubject.next({});
                    bingoCardSubject.next(null);
                }
                essSubject.next(_ess);
            }
        }
        playerIDToEss = (id) => {
            return "::" + id.toString();
        }

        initVersion();
        initlogin(); // DO this last so all subjects are defined
    }

    const notify = (typ, title, body, duration) => {
        try {
            title = title || "Unknown";
            body = body || "";
            duration = duration || 1000;
            NotificationManager[typ](body, title, duration);
        } catch (error) {
            console.log(error.data);
        }
    }

    const info = (title, body, duration) => {
        notify('info', title, body, duration);
    }
    const success = (title, body, duration) => {
        notify('success', title, body, duration);
    }
    const warning = (title, body, duration) => {
        notify('warning', title, body, duration);
    }
    const error = (title, body, duration) => {
        notify('error', title, body, duration);
    }

    const show = (err, nf, duration) => {
        let title = "Unknown Error";
        let body = "";

        try {
            if (err) {
                if (err.response && err.response.statusText) {
                    title = err.response.statusText;
                } else {
                    if (err.message)
                        title = err.message;
                }
                if (err.response && err.response.data)
                    body = err.response.data;
            }

            nf(title, body, duration || 1000);
        } catch (error) {
            console.log(error.data);
        }
    }

    const parseError = (err, duration) => {
        show(err, error, duration);
    }

    const parseWarning = (err, duration) => {
        show(err, warning, duration);
    }

    const signoff = () => {
        tok = null;
        loginSubject.next(false);
    }

    const initRetStruct = () => {
        retStruct = {
            SubscribeVersion: versionSubjectSubscribe,
            SubscribeLogin: loginSubjectSubscribe,
            SubscribeWaiting: waitingSubjectSubscribe,
            SubscribeESS: essSubjectSubscribe,
            SubscribeGames: gamesSubjectSubscribe,
            SubscribePlayers: playersSubjectSubscribe,
            SubscribeRecall: recallSubjectSubscribe,
            SubscribeCurrentGame: bingoCardSubjectSubscribe,
            Login: login,
            Get: get,
            Post: post,
            PlayerIDToESS: playerIDToEss,
            SetESS: setESS,
            Lookup: lookup,
            NavRules: navRules,
            ShowCurrentGame: setGameEss,
            Enroll: enroll,
            Enable: enable,
            UnEnroll: unenroll,
            Ignore: ignoreEss,
            ParseError: parseError,
            ParseWarning: parseWarning,
            Info: info,
            Success: success,
            Warning: warning,
            Error: error,
            SignOff: signoff,
            SessionReport: sessionReport,
            EnrollmentReport: enrollmentReport,
            DeckReport: deckReport,
            AuditReport: auditReport,
        }
    }

    return function () {
        if (!once) {
            once = true;
            isWaiting = false;
            init();
            initRetStruct();
        }
        return retStruct;
    };
})();

export const ServerIntf = serverIntf();

