import Modal from "react-modal";
import {useState} from "react";
import {Col, Container, Row} from "react-bootstrap";

export function CurrentGame(props) {
    const pubUrl = process.env.PUBLIC_URL
    const [bcModalOpen, bcModalShow] = useState(false);

    const toggleBCModal = () => {
        bcModalShow(!bcModalOpen);
    }

    if(Object.keys(props.card) && Object.keys(props.card).length > 0) {
        console.log(props.card);
        return (
            <Modal isOpen={bcModalOpen} shouldCloseOnOverlayClick={true}>
                <div>
                    <span className="table-title">Current Bingo Game - {/*props.recall.FloorLocation*/ "Place Holder" }</span>
                </div>
                <Row className="bingo-card-cont">
                    <Container className="bingo-spot-row">
                        <img src={pubUrl + "/bingo-assets/bingo-card.svg"} width="100%" className="bingo-card-img"/>
                        <Row xs={5} md={5} lg={5} className="card-spot-row">
                            {
                                props.card.CardSpots.split(",").map(function (item) {
                                    let hits = props.card.SpotsHit.split(",");
                                    if (!item) return;

                                    if (RegExp(/^\d+$/).test(item)) {
                                        return (
                                            <Col>
                                                <img src={pubUrl + "/bingo-assets/bingo-star.svg"}
                                                     className="spot-star" name={hits.indexOf(item)}/>
                                                <h4 className="spot-txt">{item}</h4>
                                            </Col>
                                        )
                                    } else {
                                        return (
                                            <Col>
                                                <img src={pubUrl + "/bingo-assets/bingo-star.svg"}
                                                     className="spot-star" name={hits.indexOf(item)}/>
                                                <img
                                                    src={item.includes("G") ? pubUrl + "/bingo-assets/god.svg" : pubUrl + "/bingo-assets/constellation.svg"}
                                                    className="spot-symbol"/>
                                            </Col>
                                        )
                                    }
                                })}
                        </Row>
                    </Container>
                    <Container>
                        <Row xs={2} md={2} lg={2} className="prize-row">
                            <img src={pubUrl + "/bingo-assets/prize-bag.svg"}
                                 className="interim-prize"
                                 name={props.card.InterimPrize === 'Grab Bag' ? 1 : 0}/>
                            <img src={pubUrl + "/bingo-assets/crown.svg"} className="end-prize"
                                 name={props.card.EndPrize ? 1 : 0}/>
                        </Row>
                    </Container>
                </Row>
            </Modal>
        );
    }
    return "";
}