import './App.css';
import './GameRecall.css';
import Table from 'react-bootstrap/Table';
import {Col, Container, Row} from 'react-bootstrap';
import {useEffect, useState} from "react";

export function GameRecall(props) {
    const pubUrl = process.env.PUBLIC_URL;
    const [ess, setESS] = useState(props.ess); // We track ess separately so we can retain the last value for display purposes

    const essState = () => {
        if (props.ess > "" && ess !== props.ess)
            setESS(props.ess);

        return ess;
    }

    const animateBingoDraws = (delay = 10) => {
        const drawRows = document.getElementsByClassName("draw-row");

        for (const row of drawRows) {
            let children = row.getElementsByClassName("draw-item");

            for (let i = 0; i < children.length; i++) {
                setTimeout(() => {
                    children[i].style.visibility = "visible";
                }, delay);
                delay += 10;
            }
        }
    }

    const animateBingoCard = (delay = 250) => {
        const cardRows = document.getElementsByClassName("bingo-card-cont");

        for (const row of cardRows) {
            let stars = row.getElementsByClassName("spot-star");

            for (let i = 0; i < stars.length; i++) {
                let index = parseInt(stars[i].name);

                setTimeout(() => {
                    if (index >= 0)
                        stars[i].style.visibility = "visible";
                }, delay * parseInt(stars[i].name));
            }
        }
    }

    const animatePrizes = (delay = 1250) => {
        const prizeRows = document.getElementsByClassName("prize-row");

        setTimeout(() => {
            for (let i = 0; i < prizeRows.length; i++) {
                if (prizeRows[i].children[0].name === "1")
                    prizeRows[i].children[0].style.visibility = "visible";
                if (prizeRows[i].children[1].name === "1")
                    prizeRows[i].children[1].style.visibility = "visible";
            }
        }, 1250);
    }

    useEffect(() => {
        animateBingoDraws();
        animateBingoCard();
        animatePrizes();
    });

    return (
        <div>
            <div>
                <span className="table-title">Game Recall - {props.recall.FloorLocation} ({essState()})</span>
            </div>
            <Table striped bordered size="md">
                <thead>
                <tr>
                    <th>Location</th>
                    <th>Card ID</th>
                    <th>Game Start</th>
                    <th>Game End</th>
                    <th>Draw</th>
                    <th>Spots Hit</th>
                    <th>Start Credits</th>
                    <th>Wager</th>
                    <th>Win</th>
                    <th>End Credits</th>
                </tr>
                </thead>
                <tbody>
                {  // If recall data and recall games are present, sort by game start time in descending order (newest games first) and populate table
                    props.recall && props.recall.Games ? props.recall.Games.sort((a, b) => (a.WagerTime > b.WagerTime ? -1 : 1)).map(function (gr) {
                        if (gr) {
                            let gameEnd = '???';

                            // In the event of no game end time, set to start(wager) time if a non-EGM player else '???'
                            if (gr.EndTime === 0) {
                                if (props.ess === '::1' || props.ess === '::2')
                                    gameEnd = new Date(gr.WagerTime).toLocaleString('en-US', {hour12: false});
                            } else {
                                gameEnd = new Date(gr.EndTime).toLocaleString('en-US', {hour12: false});
                            }

                            return (
                                <tr key={gr.SessionID}>
                                    <td>{props.recall.FloorLocation}</td>
                                    <td>{gr.CardID}</td>
                                    <td>{new Date(gr.WagerTime).toLocaleString('en-US', {hour12: false})}</td>
                                    <td>{gameEnd}</td>
                                    <td className="draw-col">
                                        <Container>
                                            <Row xs={7} md={10} lg={10} className="draw-row">
                                                {gr.DrawOrder.split(" ").map(function (draw, i) {
                                                    if (!draw)
                                                        return;
                                                    if (RegExp(/^\d+$/).test(draw)) {
                                                        return (
                                                            <Col className="draw-item">
                                                                <img src={pubUrl + "/bingo-assets/ball.svg"}
                                                                     className="ball"/>
                                                                <span className="ball-txt">{draw}</span>
                                                            </Col>
                                                        )
                                                    } else {
                                                        return (
                                                            <Col className="draw-item">
                                                                <img src={pubUrl + "/bingo-assets/ball.svg"}
                                                                     className="ball"/>
                                                                <img
                                                                    src={draw.includes("G") ? pubUrl + "/bingo-assets/god.svg" : pubUrl + "/bingo-assets/constellation.svg"}
                                                                    className="ball-symbol"/>
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </Container>
                                    </td>
                                    <td className="bingo-card-cont">
                                        <Container className="bingo-spot-row">
                                            <img src={pubUrl + "/bingo-assets/bingo-card.svg"} width="100%"
                                                 className="bingo-card-img"/>
                                            <Row xs={5} md={5} lg={5} className="card-spot-row">
                                                {gr.CardSpots.split(",").map(function (item) {
                                                    if (!item)
                                                        return;
                                                    let hits = gr.SpotsHit.split(",");
                                                    if (RegExp(/^\d+$/).test(item)) {
                                                        return (
                                                            <Col>
                                                                <img src={pubUrl + "/bingo-assets/bingo-star.svg"}
                                                                     className="spot-star" name={hits.indexOf(item)}/>
                                                                <h4 className="spot-txt">{item}</h4>
                                                            </Col>
                                                        )
                                                    } else {
                                                        return (
                                                            <Col>
                                                                <img src={pubUrl + "/bingo-assets/bingo-star.svg"}
                                                                     className="spot-star" name={hits.indexOf(item)}/>
                                                                <img
                                                                    src={item.includes("G") ? pubUrl + "/bingo-assets/god.svg" : pubUrl + "/bingo-assets/constellation.svg"}
                                                                    className="spot-symbol"/>
                                                            </Col>
                                                        )
                                                    }
                                                })}
                                            </Row>
                                        </Container>
                                        <Container>
                                            <Row xs={2} md={2} lg={2} className="prize-row">
                                                <img src={pubUrl + "/bingo-assets/prize-bag.svg"}
                                                     className="interim-prize"
                                                     name={gr.InterimPrize === 'Grab Bag' ? 1 : 0}/>
                                                <img src={pubUrl + "/bingo-assets/crown.svg"} className="end-prize"
                                                     name={gr.EndPrize ? 1 : 0}/>
                                            </Row>
                                        </Container>
                                    </td>
                                    <td>{gr.StartCredits}</td>
                                    <td>{gr.WagerAmount}</td>
                                    <td>{gr.EndTime === 0 ? '???' : gr.TotalPrize}</td>
                                    <td>{gr.EndTime === 0 ? '???' : gr.EndCredits}</td>
                                </tr>
                            )
                        }
                        return <tr></tr>
                    }) : <tr></tr>
                }
                </tbody>
            </Table>
        </div>
    );
}

/*
	finishJSON(w, map[string]interface{}{
		"ESS":           ess,
		"AssetNumber":   assetNumber,
		"FloorLocation": floorLocation,
		"SiteID":        siteID,
		"Games":         gra,
	})

type GameRecall struct {
	// This is used to tie back to base game
	ESS string // Site ID, ESS

	// These come from game start event
	WagerTime        int64  // Wager Time
	PlayerCardNumber string // Card ID
	WagerAmount      int64  // Wager Amount

	// These will need to come from game end event
	StartCredits int64 // Start Credit
	EndCredits   int64 // End Credit
	TotalPrize   int64 // Total Prize

	// These come from bingo game
	SessionID    string // Session ID
	CardID       int
	DrawOrder    string // Draw Order
	SpotsHit     string // Spots Hit
	EndPrize     bool   // End Prize
	InterimPrize string // Interim Prize

}

*/