import './App.css';
import React, {useEffect, useState} from "react";
import {ServerIntf} from './ServerIntf'
import {Login} from './Login'
import {Rules} from "./Rules";
import {Players} from './Players'
import {Games} from './Games'
import {GameRecall} from './GameRecall'
import {CurrentGame} from './CurrentGame'
import {NotificationContainer} from 'react-notifications';
import {ColorRing} from 'react-loader-spinner';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);
    const [version, setVersion] = useState('');
    const [games, setGames] = useState([]);
    const [recall, setRecall] = useState({});
    const [players, setPlayers] = useState([]);
    const [ess, setESS] = useState('');
    const [current_game, setCurrentGame] = useState({});

    useEffect(() => {
        let versionSubscriber = ServerIntf.SubscribeVersion((v) => {
            console.log("App.setVersion", v)
            setVersion(v)
        })
        let loginSubscriber = ServerIntf.SubscribeLogin((v) => {
            console.log("App.setIsLoggedIn", v)
            setIsLoggedIn(v)
        })
        let waitingSubscriber = ServerIntf.SubscribeWaiting((v) => {
            setIsWaiting(v);
        })
        let essSubscriber = ServerIntf.SubscribeESS((v) => {
            console.log("App.setESS", v)
            setESS(v)
        })
        let playersSubscriber = ServerIntf.SubscribePlayers((v) => {
            console.log("App.setPlayers",v)
            setPlayers(v)
        })
        let gamesSubscriber = ServerIntf.SubscribeGames((v) => {
            console.log("App.setGames",v)
            setGames(v)
        })
        let recallSubscriber = ServerIntf.SubscribeRecall((v) => {
            console.log("App.setRecall", v)
            setRecall(v)
        })
        let currentGameSubscriber = ServerIntf.SubscribeCurrentGame((v) => {
            console.log("App.viewCurrentGame", v);
            setCurrentGame(v);
        })

        return function () {
            versionSubscriber.unsubscribe()
            loginSubscriber.unsubscribe()
            waitingSubscriber.unsubscribe()
            essSubscriber.unsubscribe()
            playersSubscriber.unsubscribe()
            gamesSubscriber.unsubscribe()
            recallSubscriber.unsubscribe()
            currentGameSubscriber.unsubscribe()
        }
    }, []);

    return (
        <div id="App" className="App">
            <nav className="navbar navbar-expand-md navbar-dark justify-content-center"
                 style={{backgroundColor: 'rgb(22, 163, 71)'}}>
                <a href="#" style={{textDecoration: 'none'}}><span
                    className="navbar-brand abs">Grab Bag Bingo</span></a>
                <span className="navbar-text">{version}</span>
                {isLoggedIn ? (
                    <button className="nav-link-button" onClick={ServerIntf.SessionReport}>Session Report</button>
                ) : ''}
                {isLoggedIn ? (
                    <button className="nav-link-button" onClick={ServerIntf.EnrollmentReport}>Enrollment Report</button>
                ) : ''}
                {isLoggedIn ? (
                    <button className="nav-link-button" onClick={ServerIntf.DeckReport}>Deck Report</button>
                ) : ''}
                {isLoggedIn ? (
                    <button className="nav-link-button" onClick={ServerIntf.AuditReport}>Audit Report</button>
                ) : ''}
                <button className="nav-link-button" onClick={ServerIntf.NavRules}>Rules</button>
                {isLoggedIn ? (
                    <button className="nav-link-button" onClick={ServerIntf.SignOff}>SignOff</button>
                ) : ''}
            </nav>
            {isLoggedIn ? (
                <div className='container'>
                    <div className='container'>
                        <span className="page-title">Grab Bag Bingo Casino Management Tool</span>
                    </div>
                    <div className='container'>
                        <Games ess={ess} games={games}/>
                    </div>
                    <div className='container'>
                        <Players ess={ess} players={players}/>
                    </div>
                    <div className='container'>
                        <GameRecall ess={ess} recall={recall}/>
                    </div>
                </div>
            ) : window.location.pathname === "/Rules" ? (
                <Rules/>
            ) : (
                <Login/>
            )}
            <CurrentGame bcModalOpen={Object.keys(current_game) && Object.keys(current_game).length > 0} card={current_game}/>
            <ColorRing visible={isWaiting} height="80" width="80" ariaLabel="blocks-loading"
                       wrapperClass={"loading-ring"} colors={['#94f503', '#5ef327', '#16f607', '#15860e', '#849b87']}/>
            <NotificationContainer/>
        </div>
    );
}

export default App;

// <Management />